// Classes to hide elements when printing:

.no-screen {
  display: none;
}
@media print {
  .no-print, .no-print * {
    display: none !important;
  }
  .no-screen {
    display: block;
  }
}
