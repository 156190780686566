// If you want to override variables do it here
@import "variables";

// Import styles
@import "vendors/coreui";
@import "~ngx-toastr/toastr-bs4-alert";

// If you want to add something do it here
@import "custom";

@import "print";

// ng select theme
@import "~@ng-select/ng-select/themes/default.theme.css";

.clickable-table tbody tr {
  cursor: pointer;
}

.btn-group label {margin-bottom: 0}

#toast-container > div {
  opacity:1;
}

.header-with-button {line-height: 2em;}
.switch {
  font-family: "fakt-web","Helvetica Neue",Hevetica,sans-serif;
}
.switch {
  background: lighten($warning, 20%);
  &.checked {
    background: lighten($success, 10%);
  }
  &.switch-medium > .switch-pane > span {
    font-size: .9em;
  }
}

table .badge {
  font-size: 1em;
}

accordion {
  accordion:first-child {
    text-align: center;
  }
  .panel-heading.card-header {
    padding: 0;
  }
  .card.panel {
    margin-bottom: 0!important;
  }
}

.strong {
  font-weight: bold;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2363c2de' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2363c2de' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-prev, .carousel-control-next {
  opacity: 1;
}

.dataTables_processing {
  z-index: 1;
}
